<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <div class="items" style="flex: none">
        <div class="one-item" style="width: 200px">
          <a-input
              class="input"
              v-model="queryParams.username"
              placeholder="用户名称"
              allowClear
          />
        </div>
        <div class="one-item">
          <a-select
              class="input"
              v-model="queryParams.requestType"
              @change="search(1)"
              allowClear
              placeholder="支付类型"
          >
            <a-select-option v-for="r in dict['payRequestType']" :key="r.value.toString()">{{ r.label }}
            </a-select-option>
          </a-select>
        </div>
        <div class="one-item">
          <a-select
              class="input"
              v-model="queryParams.status"
              @change="search(1)"
              allowClear
              placeholder="支付状态"
          >
            <a-select-option v-for="r in dict['payRequestStatus']" :key="r.value.toString()">{{ r.label }}
            </a-select-option>
          </a-select>
        </div>

        <!--        <div class="one-item" style="width: 300px;">-->
        <!--          <label>日期</label>-->
        <!--          <range-date @change="handleDateChange" ref="createTime"></range-date>-->
        <!--        </div>-->
        <!--        <div class="one-item" style="padding-left: 10px">-->
        <!--          <a-input-->
        <!--              class="input"-->
        <!--              v-model="queryParams.mobile"-->
        <!--              placeholder="手机号码"-->
        <!--              allowClear-->
        <!--          />-->
        <!--        </div>-->
      </div>
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
        <a-button style="margin-left: 8px" @click="reset">重置</a-button>
      </div>
    </div>
    <a-table
        ref="TableInfo"
        size="small"
        :bordered="true"
        :columns="columns"
        rowKey="uid"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 1000 }"
    >

      <template slot="status" slot-scope="text, record">
        <a-tag :color="record.status | dictName('payRequestStatus', 'color')">{{
            record.status | dictName("payRequestStatus")
          }}
        </a-tag>
      </template>

      <template slot="requestType" slot-scope="text, record">
        <a-tag :color="record.requestType | dictName('payRequestType', 'color')">{{
            record.requestType | dictName("payRequestType")
          }}
        </a-tag>
      </template>

      <template slot="linkType" slot-scope="text, record">
        <template v-if="record.linkType ">
          {{ record.linkType | dictName("payLinkType") }} - {{ record.linkId }}
        </template>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-dropdown>
          <a-menu slot="overlay">
            <!--            <a-menu-item-->
            <!--                key="auth"-->
            <!--                :style="{ color: textColor }"-->
            <!--                @click="handleAction($event, record)"-->
            <!--            >-->
            <!--              <a-icon type="check"></a-icon>-->
            <!--              审核-->
            <!--            </a-menu-item>-->
            <!--            <a-menu-divider/>-->
            <!--            <a-menu-item-->
            <!--                key="setting"-->
            <!--                :style="{ color: textColor }"-->
            <!--                @click="handleAction($event, record)"-->
            <!--            >-->
            <!--              <a-icon type="setting"/>-->
            <!--              状态配置-->
            <!--              &lt;!&ndash; {{ record.helpAuth == 1 ? "取消协助权限" : "允许协助权限" }} &ndash;&gt;-->
            <!--            </a-menu-item>-->
          </a-menu>
          <a-button>
            操作
            <a-icon type="down"/>
          </a-button>
        </a-dropdown>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import {ListMixin} from "@/mixin/list-mixin";

export default {
  name: "RewardList",
  mixins: [ListMixin],
  data() {
    return {
      listGetUrl: "pay/payList"
    };
  },
  computed: {
    columns() {
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "id",
        },
        {
          title: "用户ID",
          align: "center",
          width: 100,
          dataIndex: "fansId"
        },
        {
          title: "用户名",
          width: 150,
          align: "center",
          dataIndex: "username",
        },
        {
          title: "支付类型",
          width: 100,
          align: "center",
          scopedSlots: {customRender: "requestType"},
        },
        {
          title: "支付状态",
          width: 100,
          align: "center",
          scopedSlots: {customRender: "status"},
        },
        {
          title: "支付金额",
          align: "center",
          width: 150,
          dataIndex: "amount",
        },

        {
          title: "关联数据",
          align: "center",
          width: 150,
          dataIndex: "linkType",
          scopedSlots: {customRender: "linkType"},
        },
        {
          title: "备注",
          align: "center",
          dataIndex: "remark",
        },
        {
          title: "支付时间",
          width: 155,
          align: "center",
          dataIndex: "createDate",
        },
        // {
        //   title: "操作",
        //   align: "center",
        //   dataIndex: "operation",
        //   // fixed: "right",
        //   scopedSlots: {customRender: "operation"},
        // },
        {}
      ];
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    afterFetch(data) {
      data.records.forEach(v => {
        try {
          let contentJson = JSON.parse(v.content)

          v.contentJson = contentJson || {}
        } catch (e) {
          v.contentJson = {}
        }
      })
    },
    editSuccess(operate) {
      this.fetch();
      if (!operate) {
        this.$message.success("状态配置成功");
        return;
      }
      if (operate == 1) {
        this.$message.success("审核认证成功");
      } else {
        this.$message.error("审核认证不成功");
      }
    },
    handleAction(e, row) {
      switch (e.key) {
        case "auth":
          if (row.authFlag == 2) {
            this.$message.success("该用户已经认证完成，无需再审核");
            return;
          }
          // if (row.authFlag == 0) {
          //   this.$message.success("该用户资料未完善，不支持审核");
          //   return;
          // }
          this.$refs.userModal.showModal(row, 'company');
          break;
        case "setting":
          this.$refs.settingModal.showModal(row);
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.textClick {
  color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.img-list {
  display: flex;
  justify-content: center;
}
</style>
